import { ColumnsType } from 'antd/es/table';
import { IME } from '../../@types/ime';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

export const columns: ColumnsType<IME.ICluster> = [
  {
    title: 'Cluster Label',
    dataIndex: 'name',
    render: (value, record) => {
      return <Link to={`/validate/${record._id}`}>{value}</Link>;
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: {
      compare: (a, b) => a.status.localeCompare(b.status)
    },
  },
  {
    title: 'Date/Time',
    dataIndex: 'dateTime',
    sorter: {
      compare: (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    },
    render: value => <span>{value ? dayjs(new Date(value)).format('MMM D, YYYY h:mm A') : ''} </span>
  },
  {
    title: 'User',
    dataIndex: 'user'
  },
  {
    title: 'Size',
    dataIndex: 'size',
    sorter: {
      compare: (a, b) => a.size - b.size
    }
  }
];

export const statusFilters = [
  { value: 'ALL', label: 'All' },
  { value: 'NEW', label: 'New' },
  { value: 'VALIDATED', label: 'Validated' },
  { value: 'IN_PROGRESS', label: 'In Progress' }
];