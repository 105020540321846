import React, { useMemo } from 'react';
import { IME } from '../../../@types/ime';

import { Column, ColumnConfig } from '@ant-design/charts';

interface IProps {
  stats: IME.IStatistics;
  width: number;
  height: number;
}

const ClusterSizeHistogram = (props: IProps) => {
  const sizeHist = useMemo(() => {
    return Object.entries(props.stats.clusterStatistics.clusterSizeHistogram)
      .map(([name, value]) => ({ name, value }));
  }, [props.stats]);

  const config: ColumnConfig = {
    width: props.width,
    height: props.height,
    data: sizeHist,
    style: { radiusTopLeft: 2, radiusTopRight: 2 },
    xField: 'name',
    yField: 'value'
  };

  return (
    <div>
      <h2>Cluster Size Histogram</h2>
      <Column {...config} />
    </div>
  );
};

export default ClusterSizeHistogram;