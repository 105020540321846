import { IME } from '../@types/ime';
import { throwHttpError } from '../errors/http-error';
import { buildSearchParams } from '../util/common';

class IMEService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '';
  }

  async fetchClusters(payload: IME.IFetchClustersPayload, signal?: AbortSignal): Promise<IME.IFetchClustersResponse> {
    const type = 'clusters';
    const qs = buildSearchParams(payload);

    return fetch(`${this.API}/external/validation/${type}/${payload.collectionId}?${qs}`, { signal })
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchMatches(payload: any): Promise<IME.IFetchMatchesResponse> {
    const type = 'clusters';
    const qs = buildSearchParams(payload);

    return fetch(`${this.API}/external/validation/${type}/${payload.collection}/${payload.cluster}?${qs}`)
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchCollections(): Promise<IME.ICollection[]> {
    const type = 'matching-collections';
    return fetch(`${this.API}/finalDocumentCollections/${type}`)
      .then(throwHttpError)
      .then(res => res.json());
  }

  async setMatchStatus(payload: any): Promise<Boolean> {
    const type = 'clusters';
    const body = {
      ids: payload.matches,
      status: payload.newStatus
    };

    const options: RequestInit = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      }
    };

    return fetch(`${this.API}/external/validation/${type}/${payload.collection}/${payload.cluster}/match`, options)
      .then(throwHttpError)
      .then(res => true);
  }

  async setClusterStatus(payload: any): Promise<Boolean> {
    const type = 'clusters';
    const body = {
      status: payload.newStatus
    };

    const options: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      }
    };

    return fetch(`${this.API}/external/validation/${type}/${payload.collection}/${payload.cluster}`, options)
      .then(throwHttpError)
      .then(res => true);
  }

  async getInstitutionInfo(collection: string, cluster: string): Promise<IME.IClusterMeta> {
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    return fetch(`${this.API}/external/institution/info/${collection}/${cluster}`, options)
      .then(throwHttpError)
      .then(res => res.json());
  }

  async getMatchingDetailedInfo(clusterId: string, affiliationId: string): Promise<IME.IMatchAdditionalInfo> {
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    return fetch(`${this.API}/external/affiliation/info/${clusterId}/${affiliationId}`, options)
      .then(throwHttpError)
      .then(res => res.json());
  }

  async fetchStatistics(payload: IME.IFetchStatisticsPayload, noCache: boolean, signal?: AbortSignal): Promise<IME.IStatistics> {
    return fetch(
      `${this.API}/external/validation/clustersReport/${payload.collectionId}`,
      {
        headers: { ...(noCache ? { 'Cache-Control': 'no-cache' } : {}) },
        signal
      }
    )
      .then(throwHttpError)
      .then(res => res.json());
  }
}

export default new IMEService();
