import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#47acff'
  },
  components: {
    Radio: {
      colorPrimary: '#666',
      buttonCheckedBg: '#ebedff'
    }
  }
};

export default theme;