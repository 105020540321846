import React, { useMemo } from 'react';
import classes from './header.module.sass';
import { Menu } from 'antd';
import { Header as AntDHeader } from 'antd/es/layout/layout';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { CloudCheck } from '@phosphor-icons/react';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const collection = window.location.pathname.split('/')[2] || '';
  const currentPage = useMemo(() => location.pathname.split('/')[1] || '', [location]);

  const menu: MenuItemType[] = [
    {
      key: 'browse',
      label: 'Browse',
      onClick: () => {
        navigate(`/browse?${collection ? 'collection=' + collection : ''}`);
      }
    },
    {
      key: 'reports',
      label: 'Reports',
      onClick: () => {
        navigate(`/reports`);
      }
    }
  ];

  return (
    <AntDHeader className={classes.wrapper}>
      <CloudCheck size={35} weight="fill" />
      <a href="/browse" className={classes.title}><h1>IM Evaluator</h1></a>
      <Menu
        items={menu}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[currentPage]}
        style={{ width: '400px' }}
      />
    </AntDHeader>
  );
};

export default Header;