import React from 'react';
import classes from './page-wrapper.module.sass';
import { Layout } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import { Header } from '..';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const PageWrapper = (props: IProps) => {
  return (
    <Layout className={classes.wrapper}>
      <Header />
      <Content className={classes.content}>{props.children}</Content>
      <Footer className={classes.footer}>IM Evaluator ©2023 Created by Sada Solutions</Footer>
    </Layout>
  );
};

export default PageWrapper;