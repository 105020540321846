import React, { useMemo } from 'react';
import classes from './browse.module.sass';
import { IME } from '../../@types/ime';
import { ColumnType } from 'antd/es/table';
import { columns } from '../../constants/data-columns/browse';

import { Empty, Table } from 'antd';
import { FilterBar } from '../../components/browse';

import { useBrowse } from '../../hooks';
import { SortOrder } from 'antd/es/table/interface';
import { Link, useSearchParams } from 'react-router-dom';

const BrowsePage = () => {
  const browse = useBrowse();
  let [params, setParams] = useSearchParams();

  const cols = useMemo(() => {
    const sortFiled = params.get('sort') || 'size';
    const sortDir = params.get('sortDirection') || 'desc';
    const collection = params.get('collection');
    columns[0].render = (value, record) => <Link to={`/validate/${collection}/${record._id}`}>{value}</Link>;

    return columns.map((c: ColumnType<IME.ICluster>) => (
      c.dataIndex === sortFiled ? { ...c, defaultSortOrder: (sortDir + 'end' as SortOrder) } : c)
    );
  }, [params.get('collection'), params.get('sort'), params.get('sortDirection')]);

  return (
    <div className={classes.wrapper}>
      <FilterBar
        collections={browse.collections}
        params={params}
        setParams={setParams}
      />
      {
        !Boolean(browse.items.length || browse.loading)
          ? <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Select a collection to show data"
            style={{ marginTop: 100 }}
          />
          : (
            <Table
              rowKey="_id"
              columns={cols}
              loading={browse.loading}
              dataSource={browse.items}
              onChange={(pagination, _, sorter) => {
                const newParams = new URLSearchParams(params);
                pagination.current === 1
                  ? newParams.delete('page')
                  : newParams.set('page', pagination.current?.toString() || '1');

                pagination.pageSize === 20
                  ? newParams.delete('pageSize')
                  : newParams.set('pageSize', pagination.pageSize?.toString() || '20');

                (sorter as any).field === 'size' || !(sorter as any).column
                  ? newParams.delete('sort')
                  : newParams.set('sort', (sorter as any).field || 'size');

                (sorter as any).order === 'descend' || !(sorter as any).order
                  ? newParams.delete('sortDirection')
                  : newParams.set('sortDirection', (sorter as any).order?.replace('end', '') || 'desc');

                setParams(newParams);
              }}
              pagination={{
                showSizeChanger: true,
                total: browse.total,
                pageSize: Number(params.get('pageSize')) || 20,
                current: Number(params.get('page')) || 1
              }}
            />
          )
      }
    </div>
  );
};

export default BrowsePage;