import classes from './filter-bar.module.sass';
import { IME } from '../../../@types/ime';
import { SetURLSearchParams } from 'react-router-dom';

import { Select } from 'antd';

interface IProps {
  params: URLSearchParams;
  loading: boolean;
  collections: IME.ICollection[];
  setParams: SetURLSearchParams;
}

const filterOption = (input: string, option?: { label: string; value: string; }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const FilterBar = (props: IProps) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <Select
          style={{ width: 200 }}
          disabled={props.loading}
          placeholder="Select a collection"
          filterOption={filterOption}
          value={props.params.get('collection')}
          options={props.collections.map(c => ({ label: c.label, value: c.collectionId }))}
          onChange={v => props.setParams({ collection: v })}
        />
        <div className={classes.right}></div>
      </div>
    </div>
  );
};

export default FilterBar;