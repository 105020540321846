import { message } from 'antd';
import { IME } from '../@types/ime';
import imeService from '../services/ime.service';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IState {
  stats: IME.IStatistics | null;
  collections: IME.ICollection[];
  loading: boolean;
};

const initialState: IState = {
  stats: null,
  loading: true,
  collections: []
};

const useReports = () => {
  const [state, setState] = useState<IState>(initialState);
  let [params, setParams] = useSearchParams();
  const abortController = useRef<AbortController>();

  const loadCollections = useCallback(() => {
    if (!state.loading) {
      setState(oldState => ({ ...oldState, loading: true }));
    }

    imeService.fetchCollections()
      .then(res => {
        setState(oldState => ({ ...oldState, collections: res, loading: false }));        
        setParams(params);
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: false }));
        message.error('Failed to load collections!');
      });
  }, [params]);

  const retrieve = (noCache: boolean = false) => {
    if (!params.get('collection')) {
      return;
    }

    if (!state.loading) {
      setState(oldState => ({ ...oldState, loading: true }));
    }

    let payload: IME.IFetchStatisticsPayload = {
      collectionId: params.get('collection') || '',
    };

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    imeService.fetchStatistics(payload, noCache, abortController.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, stats: res, loading: false }));
      })
      .catch(() => {
        if (!abortController.current?.abort) {
          setState(oldState => ({ ...oldState, loading: false, res: initialState.stats }));
        }
      });
  };

  useEffect(() => { retrieve(); }, [params]);

  useEffect(() => {
    loadCollections();
  }, []);

  return {
    retrieve,
    ...state
  };
};

export default useReports;