import { Pie, PieConfig } from '@ant-design/charts';
import React, { useMemo } from 'react';
import { IME } from '../../../@types/ime';

interface IProps {
  stats: IME.IStatistics;
  width: number;
  height: number;
}

const ProgressStatus = (props: IProps) => {
  const clusterProgress = useMemo(() => {
    return Object.entries(props.stats.clusterMetadataStatistics.progressStatus)
      .map(([name, value]) => ({ name, value }));
  }, [props.stats]);

  const config: PieConfig = {
    data: clusterProgress,
    width: props.width,
    height: props.height,
    insetLeft: 10,
    angleField: 'value',
    colorField: 'name',
    linkLabels: true,
    radius: 0.8,
    legend: { color: { position: 'top' } },
    tooltip: { field: 'value' },
    label: {
      position: 'outside',
      text: (item) => `${item.name}: ${item.value}`,
      transform: [{ type: 'overlapDodgeY' }],
      fontSize: 10
    },
    style: { stroke: '#fff', inset: 2, radius: 4 },
    scale: { color: { palette: 'tableau10' } }
  };

  return (
    <div>
      <h2>Cluster Progress Status</h2>
      <Pie {...config} />
    </div>
  );
};

export default ProgressStatus;
