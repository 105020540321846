import classes from './reports.module.sass';

import { ClusterSizeHistogram, CountryHistogram, FilterBar, ProgressStatus, ValidationStatus } from '../../components/reports';
import { Button, Flex, Spin, Tooltip, Empty } from 'antd';
import { ArrowClockwise } from '@phosphor-icons/react';

import { useReports } from '../../hooks';
import { useSearchParams } from 'react-router-dom';

const ReportsPage = () => {
  const reports = useReports();
  let [params, setParams] = useSearchParams();

  return (
    <div className={classes.wrapper}>
      <Flex gap={10}>
        <FilterBar
          collections={reports.collections}
          loading={reports.loading}
          params={params}
          setParams={setParams}
        />
        <Tooltip title="Clear cache and reload">
          <Button
            shape="circle"
            onClick={() => reports.retrieve(true)}
            style={{ paddingTop: '6px' }}
            icon={<ArrowClockwise size={18} />}
            disabled={!Boolean(reports.stats)}
          />
        </Tooltip>
      </Flex>
      <Spin
        spinning={reports.loading}
        size="default"
        tip="Loading Statistics..."
        style={{ margin: '50px auto', width: '100%' }}
      >
        {
          Boolean(reports.stats && !reports.loading)
            ? (
              <div className={classes.statistics}>
                <ProgressStatus stats={reports.stats} width={350} height={350} />
                <ValidationStatus stats={reports.stats} width={350} height={350} />
                <ClusterSizeHistogram stats={reports.stats} width={350} height={300} />
                <CountryHistogram stats={reports.stats} width={1000} height={400} />
              </div>
            )
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Select a collection to show statistics" />
        }
      </Spin>
    </div >
  );
};

export default ReportsPage;