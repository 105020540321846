import classes from './filter-bar.module.sass';
import { IME } from '../../../@types/ime';
import { SetURLSearchParams } from 'react-router-dom';
import { statusFilters } from '../../../constants/data-columns/browse';

import { Button, Input, Popover, Select, Slider } from 'antd';
import { FunnelSimple } from '@phosphor-icons/react';

interface IProps {
  params: URLSearchParams;
  collections: IME.ICollection[];
  setParams: SetURLSearchParams;
}

const filterOption = (input: string, option?: { label: string; value: string; }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const FilterBar = (props: IProps) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <Select
          style={{ width: 200 }}
          placeholder="Select a collection"
          filterOption={filterOption}
          value={props.params.get('collection')}
          options={props.collections.map(c => ({ label: c.label, value: c.collectionId }))}
          onChange={v => props.setParams({ collection: v })}
        />
        <Input.Search
          className={classes.searchBar}
          style={{ width: 450 }}
          enterButton
          size="middle"
          placeholder="Search clusters"
          title="Search clusters"
          allowClear
          defaultValue={props.params.get('query') || ''}
          onSearch={(v) => {
            const params = new URLSearchParams(props.params);
            params.delete('page');
            v ? params.set('query', v.trim()) : params.delete('query');
            props.setParams(params);
          }}
        />
      </div>
      <div className={classes.right}>
        <div className={classes.title}>
          <FunnelSimple size={18} /> <h3>Filter by:</h3>
        </div>
        <ul className={classes.filters}>
          <li>
            <label htmlFor="filter-c-size"><b>Cluster Size:</b></label>
            <Popover content={
              <>
                <small>Select <b>[0-1000]</b> to get all</small>
                <Slider
                  range
                  min={0}
                  max={1000}
                  defaultValue={[0, 1000]}
                  onAfterChange={(v) => {
                    const params = new URLSearchParams(props.params);
                    params.delete('page');
                    v[0] === 0
                      ? params.delete('sizeFrom')
                      : params.set('sizeFrom', v[0].toString());
                    v[1] === 1000
                      ? params.delete('sizeTo')
                      : params.set('sizeTo', v[1].toString());
                    props.setParams(params);
                  }}
                />
              </>
            }
              title="Cluster Size"
              trigger="click"
            >
              <Button type="dashed">Select Range</Button>
            </Popover>
          </li>
          <li>
            <label htmlFor="filter-status"><b>Status:</b></label>
            <Select
              id="filter-status"
              style={{ width: 170 }}
              placeholder="Select"
              onChange={(v) => {
                const params = new URLSearchParams(props.params);
                params.delete('page');
                v === 'ALL'
                  ? params.delete('status')
                  : params.set('status', v);

                props.setParams(params);
              }}
              options={statusFilters}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FilterBar;