import { message } from 'antd';
import { IME } from '../@types/ime';
import imeService from '../services/ime.service';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IState {
  items: IME.ICluster[];
  collections: IME.ICollection[];
  loading: boolean;
  total: number;
};

const initialState: IState = {
  items: [],
  loading: true,
  collections: [],
  total: 0
};

const useBrowse = () => {
  const [state, setState] = useState<IState>(initialState);
  let [params, setParams] = useSearchParams();
  const abortController = useRef<AbortController>();

  const loadCollections = useCallback(() => {
    if (!state.loading) {
      setState(oldState => ({ ...oldState, loading: true }));
    }

    imeService.fetchCollections()
      .then(res => {
        setState(oldState => ({ ...oldState, collections: res, loading: false }));
        setParams(params);
      })
      .catch(() => {
        setState(oldState => ({ ...oldState, loading: false }));
        message.error('Failed to load collections!');
      });
  }, [params]);

  const retrieve = () => {
    if (!params.get('collection')) {
      setState(oldState => ({ ...oldState, items: [] }));
      return;
    }

    if (!state.loading) {
      setState(oldState => ({ ...oldState, loading: true }));
    }

    let payload: IME.IFetchClustersPayload = {
      collectionId: params.get('collection') || '',
      query: params.get('query') || '',
      status: params.get('status') || '',
      sizeFrom: params.get('sizeFrom') || '',
      sizeTo: params.get('sizeTo') || '',
      page: Number(params.get('page')) || 1,
      pageSize: Number(params.get('pageSize')) || 20,
      sort: params.get('sort') || 'size',
      sortDirection: params.get('sortDirection') || 'desc'
    };

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    imeService.fetchClusters(payload, abortController.current.signal)
      .then(res => {
        setState(oldState => ({ ...oldState, items: res.results, total: res.total, loading: false }));
      })
      .catch(() => {
        if (!abortController.current?.abort) {
          setState(oldState => ({ ...oldState, loading: false, res: [], total: 0 }));
        }
      });
  };

  useEffect(() => { retrieve(); }, [params]);

  useEffect(() => {
    loadCollections();
  }, []);

  return {
    retrieve,
    ...state
  };
};

export default useBrowse;