import React, { useState } from 'react';
import classes from './top-bar.module.sass';
import { IME } from '../../../@types/ime';

import { Input, message, Radio, Select } from 'antd';
import { CheckCircle } from '@phosphor-icons/react';
import { SetURLSearchParams } from 'react-router-dom';

interface IProps {
  params: URLSearchParams;
  selections: React.Key[],
  setParams: SetURLSearchParams;
  onClusterAction: (status: IME.IMatchStatus) => void;
  onAction: (match: string[], status: IME.IMatchStatus) => Promise<Boolean | undefined>;
}

const TopBar = (props: IProps) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState('action');

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <div className={classes.left}>
          <Input.Search
            className={classes.searchBar}
            enterButton
            size="middle"
            placeholder="Search entities"
            title="Search entities"
            allowClear
            defaultValue={props.params.get('query') || ''}
            onSearch={(v) => {
              const params = new URLSearchParams(props.params);
              params.delete('page');
              v ? params.set('query', v.trim()) : params.delete('query');
              props.setParams(params);
            }}
          />
          <div className={classes.filter}>
            <label htmlFor="filter-status"><b>Filter by status:</b></label>
            <Select
              id="filter-status"
              style={{ width: 170 }}
              placeholder="Select"
              defaultValue={props.params.get('status') || 'ALL'}
              onChange={(v) => {
                const params = new URLSearchParams(props.params);
                params.delete('page');
                v !== 'ALL' ? params.set('status', v.trim()) : params.delete('status');;
                props.setParams(params);
              }}
              options={[
                { value: 'ALL', label: 'All' },
                { value: IME.IMatchStatus.PASS, label: 'Pass' },
                { value: IME.IMatchStatus.FAIL, label: 'Fail' },
                { value: IME.IMatchStatus.SKIP, label: 'Skip' }
              ]}
            />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.filter}>
            <label><b>Cluster Status:</b></label>
            <Radio.Group
              className={classes.actions}
              onChange={v => props.onClusterAction(v.target.value)}
            >
              <Radio.Button value={IME.IMatchStatus.PASS} type="dashed"><CheckCircle size={18} color="green" />Pass</Radio.Button>
              <Radio.Button value={IME.IMatchStatus.FAIL} type="dashed"><CheckCircle size={18} color="red" />Fail</Radio.Button>
              <Radio.Button value={IME.IMatchStatus.SKIP} type="dashed"><CheckCircle size={18} color="orange" />Skip</Radio.Button>
            </Radio.Group>
          </div>
          <div className={classes.filter}>
            <label htmlFor="filter-status"><b>Bulk Action:</b></label>
            <Select
              id="filter-status"
              style={{ width: 170 }}
              placeholder="Select"
              value={selectedBatchAction}
              onChange={v => {
                if (props.selections.length) {
                  if (v) {
                    setSelectedBatchAction(v);
                    props.onAction(props.selections.map(String), v as any)
                      .then((res) => {
                        if (res) {
                          setTimeout(() => {
                            setSelectedBatchAction('action');
                          }, 1000);
                        }
                      });
                  }
                } else {
                  message.warning("Please select at least one item", 3000);
                }
              }}
              options={[
                { value: 'action', label: 'Action', disabled: true },
                { value: IME.IMatchStatus.PASS, label: 'Pass' },
                { value: IME.IMatchStatus.FAIL, label: 'Fail' }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;