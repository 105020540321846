import { message } from 'antd';
import { IME } from '../@types/ime';
import imeService from '../services/ime.service';

import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

interface IState {
  items: IME.IMatch[];
  total: number;
  loading: boolean;
  loadingMeta: boolean;
  loadingDetailedInfo: string[];
  clusterMeta: IME.IClusterMeta | null;
};

const initialState: IState = {
  items: [],
  total: 0,
  loading: true,
  loadingMeta: true,
  loadingDetailedInfo: [],
  clusterMeta: null
};

const useValidate = () => {
  const [state, setState] = useState<IState>(initialState);
  const routerParams = useParams();
  let [params] = useSearchParams();


  const retrieveMatches = () => {
    if (!routerParams.collection || !routerParams.cluster) {
      return;
    }

    if (!state.loading) {
      setState(oldState => ({ ...oldState, loading: true }));
    }

    let payload = {
      collection: routerParams.collection,
      cluster: routerParams.cluster,
      query: params.get('query') || '',
      page: Number(params.get('page')) || 1,
      pageSize: Number(params.get('pageSize')) || 20,
      status: params.get('status') || ''
    };

    imeService.fetchMatches(payload)
      .then(res => {
        setState(oldState => ({ ...oldState, items: res.results, total: res.total }));
      })
      .finally(() => {
        setState(oldState => ({ ...oldState, loading: false }));
      });
  };

  const action = (matches: string[], status: IME.IMatchStatus) => {
    message.loading("Updating Status", 3);

    const payload = {
      collection: routerParams.collection,
      cluster: routerParams.cluster,
      matches,
      newStatus: status
    };

    return imeService.setMatchStatus(payload)
      .then(res => {
        if (res) {
          message.destroy();
          message.success("Status updated");
          retrieveMatches();
          return true;
        }
      })
      .catch(() => {
        message.destroy();
        message.error("Failed to update status!");
        return false;
      });
  };

  const clusterAction = (status: IME.IMatchStatus) => {
    message.loading("Updating Status", 3);

    const payload = {
      collection: routerParams.collection,
      cluster: routerParams.cluster,
      newStatus: status
    };

    imeService.setClusterStatus(payload)
      .then(res => {
        if (res) {
          message.destroy();
          message.success("Status updated");
          retrieveMatches();
        }
      })
      .catch(() => {
        message.destroy();
        message.error("Failed to update status!");
      });
  };

  const getInstitutionInfo = () => {
    if (routerParams.collection && routerParams.cluster) {
      if (!state.loadingMeta) {
        setState(oldState => ({ ...oldState, loadingMeta: true }));
      }

      imeService.getInstitutionInfo(routerParams.collection, routerParams.cluster)
        .then(res => {
          if (res) {
            setState(oldState => ({ ...oldState, loadingMeta: false, clusterMeta: res }));
          }
        })
        .catch(() => {
          message.destroy();
          message.error("Failed to fetch cluster meta!");
          setState(oldState => ({ ...oldState, loadingMeta: false }));
        });
    }
  };

  const getMatchingDetailedInfo = (affiliationId: string) => {
    // return if already loaded
    if (state.items.find(r => r.id === affiliationId).detailedInfo) return;

    if (routerParams.collection && routerParams.cluster) {
      if (!state.loadingDetailedInfo[affiliationId]) {
        setState(oldState => ({ ...oldState, loadingDetailedInfo: [...oldState.loadingDetailedInfo, affiliationId] }));
      }

      imeService.getMatchingDetailedInfo(routerParams.collection, affiliationId)
        .then(res => {
          if (res) {
            setState(oldState => ({
              ...oldState,
              items: oldState.items.map(r => r.id === affiliationId ? { ...r, detailedInfo: res } : r)
            }));
          }
        })
        .catch(() => {
          message.destroy();
          message.error("Failed to fetch detailed info!");
        }).finally(() => {
          setState(oldState => ({
            ...oldState,
            loadingDetailedInfo: oldState.loadingDetailedInfo.filter(r => r !== affiliationId)
          }));
        });
    }
  };

  useEffect(() => { retrieveMatches(); }, [params]);
  useEffect(() => { getInstitutionInfo(); }, [routerParams.cluster, routerParams.collection]);

  return {
    retrieveMatches,
    clusterAction,
    getInstitutionInfo,
    getMatchingDetailedInfo,
    action,
    ...state
  };
};

export default useValidate;