import classes from './actions.module.sass';
import { IME } from '../../../@types/ime';

import { Flex, Radio } from 'antd';
import { CheckCircle } from '@phosphor-icons/react';

interface IProps {
  match: IME.IMatch;
  onChange: (matches: string[], status: IME.IMatchStatus) => void;
}

const Actions = (props: IProps) => {
  return (
    <Flex wrap="nowrap">
      <Radio.Group
        className={classes.wrapper}
        value={props.match.status}
        onChange={e => {
          props.onChange([props.match._id.toString()], e.target.value);
        }}
      >
        <Radio.Button
          className={classes.button}
          value={IME.IMatchStatus.PASS}
          type="text"
        >
          <CheckCircle size={22} color="green" />Pass
        </Radio.Button>
        <Radio.Button
          className={classes.button}
          value={IME.IMatchStatus.FAIL}
          type="text">
          <CheckCircle size={22} color="red" />Fail
        </Radio.Button>
      </Radio.Group>
    </Flex>
  );
};

export default Actions;