import { IME } from '../../@types/ime';
import Table, { ColumnsType } from 'antd/es/table';

const columns: ColumnsType<IME.IMatch> = [
  Table.SELECTION_COLUMN,
  Table.EXPAND_COLUMN,
  {
    title: 'Level 1',
    dataIndex: 'levels',
    render: (value) => <span>{value[0] || ''}</span>,
    ellipsis: true
  },
  {
    title: 'Level 2',
    dataIndex: 'levels',
    render: (value) => <span>{value[1] || ''}</span>,
    ellipsis: true
  },
  {
    title: 'Level 3',
    dataIndex: 'levels',
    render: (value) => <span>{value[3] || ''}</span>,
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    width: 80,
    render: (v) => v.toUpperCase()
  },
  {
    title: 'State',
    dataIndex: 'state',
    width: 120,
    ellipsis: true
  },
  {
    title: 'City',
    dataIndex: 'city'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 180
  }
];

export default columns;