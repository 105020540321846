export namespace IME {
  export interface ICluster {
    _id: string;
    name: string;
    status: string;
    dateTime: string;
    type: string;
    user: string;
    size: number;
  }

  export interface IFetchClustersPayload {
    collectionId: string;
    query: string;
    status: string;
    sizeFrom: string;
    sizeTo: string;
    page: number;
    pageSize: number;
    sort: string;
    sortDirection: string;
  }

  export interface IFetchClustersResponse {
    results: ICluster[];
    page: number;
    total: number;
    pageSize: number;
  }

  export interface IFetchStatisticsPayload {
    collectionId: string;
  }

  export interface IStatistics {
    clusterMetadataStatistics: {
      progressStatus: {
        [key: string]: number;
      },
      validationStatus: {
        [key: string]: number;
      };
    },
    clusterStatistics: {
      clusterSizeHistogram: {
        [key: string]: number;
      },
      countryHistogram: {
        [key: string]: number;
      };
    };
  }

  export interface IFetchMatchesResponse {
    results: IMatch[];
    page: number;
    total: number;
    pageSize: number;
  }

  export interface IMatchAdditionalInfo {
    address: { city: string; state: string; country: string; };
    terms: string[];
    normTerms: string[];
    affiliationOrgName: string;
    affiliationName: string;
    affiliationId: string;
    levels: string[];
  }

  export interface IMatch {
    _id: React.Key;
    id: string;  // Affiliation id
    levels: string[];
    country: string;
    state: string;
    city: string;
    status: string;
    detailedInfo: IMatchAdditionalInfo;
  }

  export interface ICollection {
    collectionId: string;
    collectionType: string;
    label: string;
  }

  export interface IParams {
    page: number;
    pageSize: number;
    sort: string;
    sortDirection: string;
    sizeFrom: number;
    sizeTo: number;
    query: string;
    status: string;
    collection: string;
  }

  export interface IClusterMeta {
    address: {
      city: string;
      state: string;
      country: string;
      _id: string;
    };
    altNames: string[];
    collectionId: string;
    cumulativeAltNames: string[];
    id: string;
    name: string;
  }

  export enum IMatchStatus {
    PASS = 'PASS',
    FAIL = 'FAIL',
    SKIP = 'SKIPPED'
  }
}