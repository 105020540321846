import React, { useMemo, useState } from 'react';
import classes from './validate.module.sass';
import columns from '../../constants/data-columns/validate';

import { Table } from 'antd';
import { Actions, AffiliationDetailedInfo, TopBar } from '../../components/validate';
import ClusterMeta from '../../components/validate/cluster-meta/cluster-meta.component';

import { useValidate } from '../../hooks';
import { useSearchParams } from 'react-router-dom';

const ValidatePage = () => {
  const validate = useValidate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  let [params, setParams] = useSearchParams();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const cols = useMemo(() => {
    columns[columns.length - 1].render = (_, record) => <Actions match={record} onChange={validate.action} />;
    columns[columns.length - 1].width = 250;
    return columns;
  }, [validate.action]);

  return (
    <div className={classes.wrapper}>
      <ClusterMeta clusterMeta={validate.clusterMeta} />
      <TopBar
        params={params}
        setParams={setParams}
        selections={selectedRowKeys}
        onAction={validate.action}
        onClusterAction={validate.clusterAction}
      />
      <Table
        rowKey="_id"
        size="middle"
        loading={validate.loading}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
        columns={cols}
        dataSource={validate.items}
        onChange={(pagination, _, sorter) => {
          const newParams = new URLSearchParams(params);
          pagination.current === 1
            ? newParams.delete('page')
            : newParams.set('page', pagination.current?.toString() || '1');

          pagination.pageSize === 20
            ? newParams.delete('pageSize')
            : newParams.set('pageSize', pagination.pageSize?.toString() || '20');

          (sorter as any).field === 'size' || !(sorter as any).column
            ? newParams.delete('sort')
            : newParams.set('sort', (sorter as any).field || 'size');

          setParams(newParams);
        }}
        pagination={{
          showSizeChanger: true,
          total: validate.total,
          pageSize: Number(params.get('pageSize')) || 20,
          current: Number(params.get('page')) || 1
        }}
        expandable={{
          onExpand: (_, row) => validate.getMatchingDetailedInfo(row.id),
          expandedRowRender: (record) => (
            <AffiliationDetailedInfo
              record={record}
              isLoading={validate.loadingDetailedInfo.includes(record.id)}
            />
          )
        }}
      />
    </div>
  );
};

export default ValidatePage;