import React from 'react';
import classes from './affiliation-detailed-info.module.sass';

import { IME } from '../../../@types/ime';
import { Flex, Spin } from 'antd';

interface IProps {
  record: IME.IMatch;
  isLoading: boolean;  
}

const AffiliationDetailedInfo = (props: IProps) => {
  const { record } = props;
  
  return (
    <Spin
      tip="Loading additional details"
      wrapperClassName={classes.affiliationDetailedInfo}
      spinning={props.isLoading}
    >
      <div>
        {
          record.detailedInfo ? (
            <div style={{ padding: '0 40px' }}>
              <div><b>Original Name: </b>{record.detailedInfo.affiliationOrgName}</div>
              <div><b>Affiliation Name: </b>{record.detailedInfo.affiliationName}</div>
              <div><b>Address: </b>{record.detailedInfo.address.country} {record.detailedInfo.address.state} {record.detailedInfo.address.city}</div>
              <div><b>Levels: </b>
                <ul style={{ margin: 0 }}>
                  {record.detailedInfo.levels.map(l => <li>{l}</li>)}
                </ul>
              </div>
              <Flex gap={20}>
                <div><b>Terms: </b>
                  <ul style={{ margin: 0 }}>
                    {record.detailedInfo.terms.map(t => <li>{t}</li>)}
                  </ul>
                </div>
                <div><b>Norm Terms: </b>
                  <ul style={{ margin: 0 }}>
                    {record.detailedInfo.normTerms.map(t => <li>{t}</li>)}
                  </ul>
                </div>
              </Flex>
            </div>
          ) : !props.isLoading ? 'No additional info!' : ''
        }
      </div>
    </Spin>
  );
};

export default AffiliationDetailedInfo;