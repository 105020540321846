import classes from './cluster-meta.module.sass';
import { IME } from '../../../@types/ime';
import { ItemType } from 'rc-collapse/es/interface';

import { Collapse } from 'antd';

interface IProps {
  clusterMeta: IME.IClusterMeta | null;
}

const ClusterMeta = (props: IProps) => {
  if (!props.clusterMeta) return null;

  const label = <>
    <b>Matching: &nbsp;</b>{props.clusterMeta.name}&nbsp;&nbsp;-&nbsp;&nbsp;
    {props.clusterMeta.address.country && <b>Country: </b>}{props.clusterMeta.address.country.toUpperCase()}
    {props.clusterMeta.address.city && <b>&nbsp; | City: </b>}{props.clusterMeta.address.city}
    {props.clusterMeta.address.state && <b>&nbsp; | State: </b>}{props.clusterMeta.address.state}
  </>;

  const altNames: ItemType[] = [];

  if (props.clusterMeta.altNames?.length) {
    altNames.push({
      key: '1',
      label: 'Alternative names',
      children: <ul style={{ margin: 0 }}>{props.clusterMeta.altNames.map(item => (<li key={item}>{item}</li>))}</ul>
    });
  }

  if (props.clusterMeta.cumulativeAltNames?.length) {
    altNames.push({
      key: '2',
      label: 'Cumulative Alternative names',
      children: <ul style={{ margin: 0 }}>{props.clusterMeta.cumulativeAltNames.map(item => (<li key={item}>{item}</li>))}</ul>
    });
  }

  return (
    <div className={classes.wrapper}>
      <Collapse
        ghost
        items={[{
          key: '1',
          label,
          children: (
            <div style={{ marginBottom: '15px' }}>
              <Collapse
                items={altNames}
                size="small"
                style={{ width: '60%' }}
                expandIconPosition="end"
              />
            </div>
          )
        }]} />
    </div>
  );
};

export default ClusterMeta;