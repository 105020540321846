import React, { useMemo } from 'react';
import { IME } from '../../../@types/ime';

import { Pie, PieConfig } from '@ant-design/charts';

interface IProps {
  stats: IME.IStatistics;
  width: number;
  height: number;
}

const ValidationStatus = (props: IProps) => {
  const validationStatus = useMemo(() => {
    return Object.entries(props.stats.clusterMetadataStatistics.validationStatus)
      .map(v => ({
        name: v[0],
        value: v[1],
      }));
  }, [props.stats]);

  const config: PieConfig = {
    data: validationStatus,
    width: props.width,
    height: props.height,
    insetLeft: 10,
    angleField: 'value',
    colorField: 'name',
    linkLabels: true,
    radius: 0.8,
    legend: { color: { position: 'top' } },
    tooltip: { field: 'value' },
    label: {
      position: 'outside',
      text: (item) => `${item.name}: ${item.value}`,
      transform: [{ type: 'overlapDodgeY' }],
      fontSize: 10
    },
    style: { stroke: '#fff', inset: 2, radius: 4 },
    scale: { color: { palette: 'tableau10' } }
  };

  return (
    <div>
      <h2>Validation Status</h2>
      <Pie {...config} />
    </div>
  );
};

export default ValidationStatus;