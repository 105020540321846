import React, { useMemo } from 'react';
import { IME } from '../../../@types/ime';

import { Column, ColumnConfig } from '@ant-design/charts';

interface IProps {
  stats: IME.IStatistics;
  width: number;
  height: number;
}

const CountryHistogram = (props: IProps) => {
  const sizeHist = useMemo(() => {
    return Object.entries(props.stats.clusterStatistics.countryHistogram)
      .map(([name, value]) => ({ name: name.toUpperCase(), value }));
  }, [props.stats]);

  const config: ColumnConfig = {
    width: props.width,
    height: props.height,
    data: sizeHist,
    style: { radiusTopLeft: 2, radiusTopRight: 2 },
    xField: 'name',
    yField: 'value',
    slider: { x: true },
    onReady: (chartsInstance) => {
      chartsInstance.emit('sliderX:filter', {
        data: { selection: [[0.1, 0.2], undefined] },
      });
    }
  };

  return (
    <div>
      <h2>Country Histogram</h2>
      <Column {...config} />
    </div>
  );
};

export default CountryHistogram;