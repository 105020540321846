import { ConfigProvider } from 'antd';
import theme from './constants/theme';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { BrowsePage, ReportsPage, ValidatePage } from './pages';
import { PageWrapper } from './components/core';

function App() {

  return (
    <ConfigProvider
      theme={theme}>
      <BrowserRouter basename="/">
        <PageWrapper>
          <Routes>
            <Route path="/browse" element={<BrowsePage />} />
            <Route path="/validate/:collection/:cluster" element={<ValidatePage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="*" element={<Navigate to="/browse" replace />} />
          </Routes>
        </PageWrapper>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
